// src/pages/Contact.js
import React, { useCallback } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
    width: '100%',
    height: '400px'
};

const center = {
    lat: 40.712776,
    lng: -74.005974
};

const locations = [
    { lat: 40.712776, lng: -74.005974 },  // New York
    { lat: 34.052235, lng: -118.243683 }, // Los Angeles
    { lat: 41.878113, lng: -87.629799 }   // Chicago
];

const Contact = () => {
    const onLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds();
        locations.forEach(location => bounds.extend(location));
        map.fitBounds(bounds);
    }, []);

    return (
        <div>
            <h1>Contact Page</h1>
            <p>Get in touch with us.</p>
            <LoadScript googleMapsApiKey="">
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={4}
                    onLoad={onLoad}
                >
                    {locations.map((location, index) => (
                        <Marker key={index} position={location} />
                    ))}
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

export default Contact;
